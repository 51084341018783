<template>

	<div class="page">
		<div class="comView">
			<div class="comItem">
				<div class="comView-left" v-if="Info">
					<div>
						<div>订单编号</div>
						<div>{{ Info.no }}</div>
					</div>
					<div>
						<div>订单金额</div>
						<div>{{ getallprice }}</div>
					</div>
					<div>
						<div>下单时间</div>
						<div>{{ Info.orderTime }}</div>
					</div>
					<div>
						<div>采购联系人</div>
						<div>{{ Info.procurementManager }}</div>
					</div>
					<div>
						<div>采购联系方式</div>
						<div>{{ Info.procurementPhone }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="comSteps">
			<el-steps :space="2000" :active="active" finish-status="success" >
				<el-step title="提交订单"></el-step>
				<el-step title="已接单"></el-step>
				<el-step title="支付成功"></el-step>
				<el-step title="商品出库"></el-step>
				<el-step title="交易完成"></el-step>
			</el-steps>
		</div>
		<div class="view">
			<div class="comTitle">采购单信息</div>
			<el-table :data="cartList">
				<el-table-column label="标题" prop="goodName">
					<template slot-scope="props">
						{{ props.row.goodsName }}
					</template>
				</el-table-column>
				<el-table-column label="规格" prop="skuName">
					<template slot-scope="props">
						{{ props.row.skuName }}
					</template>
				</el-table-column>
				<el-table-column label="单价" prop="price">
					<template slot-scope="props">
						￥{{ props.row.price }}
					</template>
				</el-table-column>
				<el-table-column label="数量" prop="count">
					<template slot-scope="props">
						{{ props.row.count }}
					</template>
				</el-table-column>
				<el-table-column label="小计" prop="">
					<template slot-scope="props">
						￥{{ props.row.count * props.row.price }}元
					</template>
				</el-table-column>
			</el-table>
			<div>
				<div class="comTitle">收货信息</div>
			</div>

			<div class="viewBtn" style="margin: 20px">
				<div>
					<div> <span>收货人:{{ Info.receiver }}</span></div>
					<div> <span>联系方式:{{ Info.receiverPhone }}</span></div>
					<div> <span>收货地址:{{ Info.receiverAddress }}</span></div>
				</div>
				<div class="viewBtn1">
					<div>商品合计：￥{{ getallprice }}元</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			// 筛选
			keyword: '',
			cartList: [],
			Info: {},
			active:1
		}
	},
	computed: {
		ShopInfo() {
			return this.$store.state.shopInfo
		},
		getallprice() {
			let all = 0;
			for (let i = 0; i < this.cartList.length; i++) {
				all += this.cartList[i].price * this.cartList[i].count
			}
			return all
		}
	},
	mounted() {
		console.log(this.$route.query.id, '???');
		this.getcartList();
	},
	methods: {
		// 获取采购车
		getcartList() {
			let params = {
				id: this.$route.query.id
			}
			this.$http.get("/product/mall/order/info", params).then(res => {
				if (res.code === 0) {
					this.Info = res.data
					this.cartList = res.data.orderItems
					this.active=(res.data.orderState.id)+1
				}
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.page {
	height: 100%;
	color: $fontColor;
	display: flex;
	flex-direction: column;
	background: #fff;
	padding: 20px 50px;

	.comTitle {
		margin: 10px 20px;
		font-size: 18px;
	}

	.comView {
		width: 100%;
		background-color: #fff;
		border: 1px solid gainsboro;

		font-size: 15px;
	}

	.comItem {
		padding: 10px 20px;
		// display: flex;
		// justify-content: space-between;

	}

	.comView-left {
		line-height: 30px;
		display: flex;
		justify-content: space-around;

	}

	.view {
		overflow: hidden;
		flex: 1;
		background: #fff;
		border: 1px solid gainsboro;
		display: flex;
		flex-direction: column;

		.tableView {
			flex: 1;

		}
	}

	.comSteps {
		width: 80%;
		margin: 30px auto;
	}

	.viewBtn {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		padding: 0 20px;
		line-height: 30px;
	}

	.viewBtn1 {
		float: right;
	}
}
</style>
